import React, { useState } from 'react';
import './App.css';
import logo from './bell-bag.png';
import List from './List';
import ListBestBells from './ListBestBellBets';

function App() {

  const dt = new Date();
  const curMonth = dt.getMonth();
  const curHour = dt.getHours();

  const API = 'https://acnhapi.com';

  const [fish, setFish] = useState();
  const [sea, setSea] = useState();
  const [bugs, setBugs] = useState();

  return (
    <div className="App">
      <header className="App-header">
        <h1>Be$t Bell Bets <img className="logo" src={logo} alt="bell bag" /></h1>
      </header>

      <h4>Showing Data for Current Time ({dt.toLocaleDateString('us-EN')} @ {dt.toLocaleTimeString('us-EN')})</h4>
      
      <div className="main">

        <div className="col">
          <h2 className="list-head">Best Bell Bets</h2>
          <ListBestBells bugState={bugs} fishState={fish} seaState={sea} />
        </div>

        <div className="col">
          <h2 className='list-head'>Available Bugs</h2>
          <List curMonth={curMonth} curHour={curHour} api={API} fetch="bugs" neededState={bugs} neededUpdateState={setBugs} />
        </div>

        <div className="col">
          <h2 className='list-head'>Available Fish</h2>
          <List curMonth={curMonth} curHour={curHour} api={API} fetch="fish"  neededState={fish} neededUpdateState={setFish}  />
        </div>

        <div className="col">
          <h2 className='list-head'>Available Sea Creatures</h2>
          <List curMonth={curMonth} curHour={curHour} api={API} fetch="sea" neededState={sea} neededUpdateState={setSea}  />
        </div>

      </div>
    </div>
  );
}

export default App;
