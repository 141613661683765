import React  from 'react';

const List = (props) => {

    let bugs = (props.bugState) ? props.bugState.data : {};
    let fish = (props.fishState) ? props.fishState.data : {};
    let sea = (props.seaState) ? props.seaState.data : {};
    let allCreatures = {...bugs, ...fish, ...sea};

    return (
        <table className="table-best">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Location</th>
                </tr>
            </thead>
            <tbody>
            {
                Object.values(allCreatures)
                    .sort(function (a,b) {
                        if(a.price > b.price) return -1;
                        if(a.price < b.price) return 1;
                        // Sort by location secondarily
                        if(a.availability.location > b.availability.location) return -1;
                        if(a.availability.location < b.availability.location) return 1;
                    })
                    .map(creature => (
                        <tr key={creature.name["name-USen"]} className={ ((creature["price-cj"]) ? "fish" : "") + ((creature["price-flick"]) ? "bug" : "") }>
                            <td>{creature.name["name-USen"]}</td>
                            <td>{creature.price.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
                            <td>{(creature.availability.location)?creature.availability.location:'Out in the deep'}</td>
                        </tr>
                    ))
            }
            </tbody>
        </table>
    );
};

export default List;