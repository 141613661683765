import React, { useState, useEffect }  from 'react';

const List = (props) => {

    const [isLoaded, setIsLoaded] = useState(false);
  
    useEffect(() => {
        const getIt = () => {
            fetch(props.api + '/v1/' + props.fetch)
            .then(response => response.json())
            .then(data => {
                props.neededUpdateState({ data })
                setIsLoaded( true );
            })
        }

        getIt();
    }, []);

    if ( !isLoaded ) {
        return ( 'No data yet...' );
    }

    else {

        return (
            <table className={"table-" + props.fetch}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Location</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.values(props.neededState.data)
                            .filter(thing => (
                                thing.availability["time-array"].includes(props.curHour) 
                                &&
                                thing.availability["month-array-northern"].includes(props.curMonth)
                            ))
                            .sort((a,b) => (a.price < b.price) ? 1 : -1)
                            .map(thing => (
                                <tr key={thing.id}>
                                    <td>{thing.name["name-USen"]}</td>
                                    <td>{thing.price.toLocaleString('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
                                    <td>{(thing.availability.location)?thing.availability.location:'Out in the deep'}
                                    </td>
                                </tr>
                            ))
                    }
                </tbody>
            </table>
        );
    }
};

export default List;